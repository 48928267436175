<template>
  <div v-style:bg="backgroundColor" :class="{ 'py-6': backgroundColor }">
    <div :class="{ container: !isFullWidth }">
      <div v-style:m="containerMargin">
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="[
            titleStyle,
            titleStyleClasses, {
              'mx-4': isFullWidth,
              'text-center': alignment === 'center' || titleAlignment === 'center' || titlePosition === 'center',
            }]"
        >
          {{ title }}
        </h2>
        <p v-if="subtitle" v-style:c="subtitleColor" :class="[subtitleStyle, { '<md:px-4': isStackedLayout }]" class="pb-3">
          {{ subtitle }}
        </p>
        <vf-carousel-elevate
          ref="carousel"
          v-bind="carouselSettings"
          :style-container="{
            '--p': mobileSlidePadding,
            '--p-md': 0,
            ...(sm?.slidePadding ? { '--mx': `${spacing[sm.slidePadding]}rem` } : {}),
            ...(md?.slidePadding ? { '--mx-md': `${spacing[md.slidePadding]}rem` } : {}),
            ...(lg?.slidePadding ? { '--mx-lg': `${spacing[lg.slidePadding]}rem` } : {}),
          }"
          :class-container="classContainer"
          :class-controls="classControls"
          :class="[collectionCenter, carouselClasses]"
        >
          <template #default="{ paused }">
            <div
              v-for="item in items"
              :key="item.id"
              v-style="{ 'max-w': slideMaxWidth, 'p': slidePadding, 'w': slideWidth }"
              :interval="
                item.media?.[$viewport.breakpoint]?.duration
                  ? item.media?.[$viewport.breakpoint]?.duration * 1000
                  : undefined
              "
            >
              <component
                :is="`lazy-cms-${resolveContentComponent(item, mappings)?.component}`"
                :content="item"
                :paused
                is-part-of-collection
                class="h-full"
                :class="[itemClass, { 'children:h-inherit': isFullHeight }]"
                :style="{ height: isFullHeight
                  ? `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})` : undefined }"
              />
            </div>
          </template>
          <template #pagination="{ activeItem }">
            <div
              v-if="carouselSettings.showPagination && items.length"
              class="flex justify-center pt-6 space-x-2 "
            >
              <base-button
                v-for="(_, i) in items"
                :key="i"
                :aria-label="replaceAll($t.goToSlide, { slide: i + 1 })"
                :aria-current="activeItem === i"
                :class="activeItem === i ? 'b-grey-10 bg-grey-10' : 'b-grey-40 '"
                class="h-2 w-2 b rounded-full "
                @click="carousel.scrollTo(i)"
              />
            </div>
          </template>
        </vf-carousel-elevate>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import mappings from '#content/mappings'
import { resolveContentComponent } from '#content/utils'
import type { Responsive } from '#types/common'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const { breakpoints } = useAppConfig().ds
const { classControls: defaultClassControls, showArrowsOnlyOnHover, sizeControls, spacing } = useAppConfig().components.cms.collection

const header = useHeaderStore()
const { $viewport } = useNuxtApp()

const carousel = ref()

const { lg, md, sm } = content.breakpoints

// ===================================
// Temporary Solution for stacked collection
// start
// ===================================
const isStackedLayout = computed(() => content?.name!.indexOf('[stacked]') >= 0)

if (isStackedLayout.value) {
  // eslint-disable-next-line vue/no-mutating-props
  content.isFullWidth = true
  if (lg) {
    lg.type = 'grid'
    lg.slideWidth = '100%'
  }

  if (md) {
    md.type = 'grid'
    md.slideWidth = '100%'
  }
}
// ===================================
// Temporary Solution for stacked collection
// end
// ===================================

const {
  alignment,
  backgroundColor,
  equalTargetSize,
  isFullHeight,
  isFullWidth,
  subtitle,
  subtitleColor,
  subtitleStyle,
  title,
  titleAlignment,
  titleColor,
  titlePosition,
  titleStyle,
} = content

const { carouselClasses, itemClass, itemsProp, titleStyleClasses } = componentTypeConfig[content.type] || {}
const items = (itemsProp ? content[itemsProp] : content.items) || []

const collectionCenter = alignment === 'center' && {
  'lg:justify-center lg:flex': lg?.type === 'carousel',
  '~md:justify-center ~md:flex': md?.type === 'carousel',
  '<md:justify-center <md:flex': sm?.type === 'carousel'
}

const getItemWidth = (w?: string) => {
  if (w === 'auto') return `${100 / items.length}%`
  return w || 'auto'
}

const getItemMaxWidth = (maxW?: string) => maxW || 'none'

const slideWidth: Record<keyof Responsive, string> = Object.keys(breakpoints).reduce((acc, cur) => {
  const width = getItemWidth(
    getValueForBreakpoint(cur as keyof Responsive, equalTargetSize)
    && content.type === 'VfCanvasCTAButtonCollection'
    && content.breakpoints[cur].type !== 'grid'
      ? `calc(12.5rem + ${2 * spacing[content.breakpoints[cur].slidePadding] || 0}rem)`
      : content.breakpoints[cur].slideWidth
  )

  return { ...acc, [cur]: width }
}, {} as Record<keyof Responsive, string>)

const slideMaxWidth: Record<keyof Responsive, string> = {
  sm: getItemMaxWidth(sm?.slideMaxWidth),
  md: getItemMaxWidth(md?.slideMaxWidth),
  lg: getItemMaxWidth(lg?.slideMaxWidth)
}

// Mobile carousel centring
const mobileSlideWidth = Number.parseInt(slideWidth.sm)
const mobileSlidePadding = Number.isNaN(mobileSlideWidth) ? 0 : `0 0 0 ${(100 - mobileSlideWidth) / 2}%`

const containerMargin: Record<keyof Responsive, any> = {
  sm: sm?.margin && `0 ${sm.margin}`,
  md: md?.margin && `0 ${md.margin}`,
  lg: lg?.margin && `0 ${lg.margin}`
}

// Create padding for each slide
const slidePadding = Object.keys(breakpoints)
  .reduce((acc, bp) => ({
    ...acc,
    ...(content.breakpoints[bp]?.slidePadding && {
      [bp]: `${spacing[content.breakpoints[bp].slidePadding] || 0}rem`
    })
  }), {})

const classControls = ((): string[] => {
  const hiddenClass = {
    sm: '<md:hidden',
    md: '~md:hidden',
    lg: 'lg:hidden'
  }

  return Object.keys(hiddenClass).reduce((acc, bp) => {
    if (content.breakpoints[bp]?.type === 'grid') return [...acc, hiddenClass[bp]]
    // show arrows by default if showArrows is not set
    return [...acc, ...(content.breakpoints[bp]?.showArrows ?? true) ? [] : [hiddenClass[bp]]]
  }, [defaultClassControls])
})()

/**
 * Get style classes for carousel containers
 */
const classContainer = [
  // Mobile slides centring
  'scroll-pa-$p',
  'md:scroll-pa-$p-md',
  // Create a negative margin for the container to compensate slides paddings
  sm?.slidePadding ? '<md:-mx-$mx' : '',
  md?.slidePadding ? '~md:-mx-$mx-md' : '',
  lg?.slidePadding ? 'lg:-mx-$mx-lg' : '',
  // Carousel vs Grid Layout
  {
    '<md:wrap': sm?.type === 'grid',
    '~md:wrap': md?.type === 'grid',
    'lg:wrap': lg?.type === 'grid'
  }
]

const carouselSettings = computed(() => {
  const {
    autoplay,
    autorotateTime = 0,
    loop,
    showArrows,
    showIndicators,
    showProgress,
    type
  } = content.breakpoints[$viewport.breakpoint] || {}

  return {
    autoplay,
    dragFree: content.type === 'VfCanvasCTAButtonCollection',
    highlightCenter: content.variant === 'highlightCenter',
    interval: autorotateTime * 1000,
    loop,
    sizeControls,
    showArrows,
    showArrowsOnlyOnHover,
    showPagination: showIndicators,
    showProgress,
    active: type === 'carousel'
  }
})
</script>
