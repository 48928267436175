import type { MonetateEvent, MonetateExtractedProduct, MonetateMappedExperience, MonetateRecEvent, MonetateRecommendedProduct } from '#types/p13n'
import { gtm } from '#root/env/features'

export const useMonetate = () => {
  const { monetateConfig } = useFeatureFlags()
  const { brand } = useRuntimeConfig().public
  const { $gtm } = useNuxtApp()
  const auth = useAuthStore()
  const route = useRoute()
  const countryCode = useCountryCode()
  const locale = useLocale()
  const region = useRegion()

  const monetateLocale = locale.replace(/-(\w)/, (match, p1) => `${p1.toUpperCase()}`)
  const SHOWCASE_URL = 'a-407c3023/d/showcase-canvas.com.sandbox'
  const loggedInCookie = useApiCookie(cookiesPostfix.Login)
  const customerId = computed(() => auth.loggedIn ? auth.consumerId || loggedInCookie.value : null)
  const channel = route.fullPath.includes('showcase') ? SHOWCASE_URL : monetateConfig?.monetateChannel

  const sendMonetateEvents = async (events: MonetateEvent[]) => {
    if (!events.length) return
    const { data } = await useApi().decide.$decideCreate(brand, {
      customerId: customerId?.value || undefined,
      channel,
      events,
      monetateId: useCookie('mt.v')?.value
    })
    return data?.responses?.[0]?.actions
  }

  const sendRecImpressionsEvent = (
    products,
    limit: number
  ) => {
    if (!products) return
    const recImpressionsProducts = products.slice(0, limit)
    const recImpressions = recImpressionsProducts.map(({ recToken }) => recToken)
    if (!recImpressionsProducts.length) return
    return sendMonetateEvents([{
      eventType: 'monetate:record:RecImpressions',
      recImpressions
    }] as MonetateRecEvent[])
  }

  const extractProductWithLocale = (product: MonetateRecommendedProduct) => {
    const url = new URL(product[`link-${monetateLocale}`])
    return {
      price: {
        lowCurrent: product[`salePrice-${countryCode.toLowerCase()}`],
        highCurrent: product[`salePrice-${countryCode.toLowerCase()}`],
        lowOriginal: product[`price-${countryCode}`],
        highOriginal: product[`price-${countryCode}`]
      },
      title: product[`title-${locale}`],
      url: removeLocale(`${url.pathname}${url.search}`)
    }
  }

  const extractProductWithoutLocale = (product: MonetateRecommendedProduct) => {
    const url = new URL(product.link)
    return {
      price: {
        lowOriginal: product.price,
        highOriginal: product.price,
        lowCurrent: product.salePrice,
        highCurrent: product.salePrice
      },
      title: product.title,
      url: removeLocale(`${url.pathname}${url.search}`)
    }
  }

  const extractProduct = (product: MonetateRecommendedProduct, showRatings?: boolean): MonetateExtractedProduct => {
    const { title, ...extra } = region === 'EMEA' ? extractProductWithLocale(product) : extractProductWithoutLocale(product)

    return {
      id: product.itemGroupId,
      image: {
        src: product.imageLink,
        alt: title,
        type: 'image'
      },
      sku: product.id,
      title,
      recToken: product.recToken,
      ...(showRatings && {
        rating: {
          showScore: product.stars !== undefined && showRatings,
          score: Number.parseFloat(product.stars || '0')
        }
      }),
      ...extra
    }
  }

  const sendToContentSquare = (actions) => {
    let disableCallback = false
    const pushCSSettings = () => {
      if (!disableCallback) {
        disableCallback = true
        if ((window as any).CS_CONF)
          (window as any).CS_CONF.integrations.push('Monetate Server Side - v1.0.0')
      }
    }

    const pushToCS = (csKey: string, csValue: string) => {
      csKey = `AB_Mon_SS_${csKey}`;
      (window as any)._uxa?.push([
        'trackDynamicVariable',
        { key: csKey, value: csValue }
      ])
    }
    actions.forEach((action) => {
      pushToCS(action.label, action.variant)
    });
    (window as any)._uxa?.push(['afterPageView', pushCSSettings])
  }

  const sendMonetateEventsToTracking = (experiences: MonetateMappedExperience[]) => {
    if (experiences.length > 0) {
      gtm && $gtm.push('product.onMonetateActiveExperiences', experiences)
      sendToContentSquare(experiences)
    }
  }

  return {
    sendMonetateEvents,
    sendMonetateEventsToTracking,
    sendRecImpressionsEvent,
    extractProduct,
    channel,
  }
}
