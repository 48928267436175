/**
 * @remark Store could be anything like user, cart, screen.
 * The first argument is a unique id of the store across your application
 */
import type { AddAddressPayload, UpdateAddressPayload } from '#root/api/clients/consumer/data-contracts'
import { ApiErrorCode } from '#root/enums/api'
import type { PaymentInstrument } from '#types/cart'
import type { BaseAddress } from '#types/components/account/address'

export const useProfileStore = defineStore('profile', () => {
  const { consumer } = useApi()

  const {
    data,
    pending,
    error,
    execute
  } = consumer.getProfileInfo({ immediate: false })
  // setting to false because with { immediate: false } pending is still initially true: https://github.com/nuxt/nuxt/issues/19333
  pending.value = false

  const { $t } = useNuxtApp()
  const profile = computed(() => data.value?.consumerProfile)
  const details = computed(() => profile.value?.consumerDetails)
  const isLoyaltyMember = computed(() => profile.value?.isLoyaltyMember)
  const addresses = computed(() => details.value?.address || [])
  const shippingAddresses = computed(() => sortAddressByDefault(addresses.value.filter((address) => address.approachType === 'S')))
  const billingAddresses = computed(() => sortAddressByDefault(addresses.value.filter((address) => address.approachType === 'B')))
  const interests = computed(() => (profile.value?.preferences?.interests?.split('|').filter(Boolean) || []))
  const defaultShippingAddress = computed(() => shippingAddresses.value.find((address) => address.main))
  const defaultBillingAddress = computed(() => billingAddresses.value.find((address) => address.main))
  const preferredShoeSize = computed(() => profile.value?.preferences?.preferredShoeSize)
  const subscriptions = computed(() => profile.value?.subscriptions
    ? profile.value.subscriptions
      .filter((subscription) => subscription.optin === true)
      .reduce((reducer, subscription) => {
        reducer[subscription.channel] = subscription
        return reducer
      }, {})
    : {}
  )
  const hasNewsletterSubscription = computed(() => profile.value?.subscriptions?.some(
    (subscription) =>
      subscription.type === 'Newsletter'
      && subscription.channel === 'email'
      && subscription.doubleOptin === true
  ))
  const employeeDiscountDetails = computed(() => details.value?.employee && replaceAll($t.associateDiscount, {
    currentSpend: useFormattedPrice(details.value.employee.currentSpend, useCurrencyCode()),
    annualCap: useFormattedPrice(details.value.employee.annualCap, useCurrencyCode()),
    Brand: $t.brandName
  }))

  const athlete = computed(() => details.value?.athlete)

  const isPartiallyEnrolled = computed(() => {
    if (!details.value)
      return false

    const { birthDate, email, firstName, lastName, phone, postalCode } = details.value

    return (email && !(phone && firstName && lastName && postalCode && birthDate))
  })

  const isWranxProfile = computed(() =>
    profile.value?.consumerDetails?.identity?.some(({ type }) => type === 'WRANX')
  )

  const isIpaProfile = computed(() =>
    profile.value?.consumerDetails?.identity?.some(({ type }) => type === 'IPA')
  )

  const isWranxOrIpaProfile = computed(() => isWranxProfile.value || isIpaProfile.value)

  async function updateBasicInfo(consumerDetails: {
    firstName?: string
    lastName?: string
    phone?: string
    phoneCode?: string
    postalCode?: string
    gender?: 'M' | 'F' | 'NA' | string
  }) {
    if (!profile.value) throw new Error('Profile not loaded')
    // check if the consumerProfile is empty, don't run update since it will cause an additional call that will trigger double emails
    if (!Object.values(consumerDetails).some(Boolean)) return

    const payload = { consumerDetails }
    if (typeof consumerDetails.phone === 'string')
      payload.consumerDetails.phone = formatE164phone(consumerDetails.phone, consumerDetails.phoneCode)

    await consumer.$updateProfile({
      consumerProfile: payload
    })
    profile.value.consumerDetails = { ...profile.value.consumerDetails, ...payload.consumerDetails }
  }

  async function updateInterests(payload) {
    if (!profile.value) throw new Error('Profile not loaded')
    const preferences = { ...profile.value.preferences, interests: payload.interests.join('|'), preferredShoeSize: payload.preferredShoeSize }
    await consumer.$updateProfile({
      consumerProfile: { consumerDetails: {}, preferences }
    })
    profile.value.preferences = preferences
  }

  function setDefaultAddress(addressId: string) {
    if (!profile.value) throw (new Error('Profile not loaded'))

    const approachType = addressId.substring(0, 1)

    const currentDefaultAddress = profile.value.consumerDetails.address
      .find((address) => address.id !== addressId && address.approachType === approachType && address.main)

    if (currentDefaultAddress)
      currentDefaultAddress.main = false

    profile.value.consumerDetails.address.find((address) => address.id === addressId).main = true
  }

  /**
   * add the from api and update the store
   * add address the Nuxt server endpoint or endpoint from LaunchDarkly config.
   * @param {BaseAddress} payload
   * @example useLazyApi('/consumers/v3/consumer/address')
   */
  async function addAddress(payload: BaseAddress) {
    if (!profile.value) throw (new Error('Profile not loaded'))

    // ensure the address is flagged as default if the user does not have one already of the same type
    // even if the user does not check the "default" checkbox
    const shouldBeMain = !addresses.value.some((a) => a.approachType === payload.approachType)

    const address = {
      ...payload,
      recipientContactPhone: formatE164phone(payload.phone, payload.phoneCode),
      main: payload.main || shouldBeMain
    } as AddAddressPayload['address']

    const { addressId } = await consumer.$addAddress({ address })

    // First time adding address to My account
    if (!profile.value.consumerDetails.address)
      profile.value.consumerDetails.address = [{ ...address, id: addressId }]
    else
      profile.value.consumerDetails.address.unshift({ ...address, id: addressId })

    if (payload.main)
      setDefaultAddress(addressId)

    return addressId
  }

  // "recaptcha_response" is not camelCase to match the API spec
  async function saveCreditCard(payload, recaptcha_response) {
    try {
      const { card } = await consumer.$savePaymentInsturment({
        ...payload,
        recaptcha_response
      })

      if (card.paymentInstrumentId) return card.paymentInstrumentId
    }
    catch (error) {
      assertApiError(error)
      const toast = useToaster()
      toast.add({
        autoClose: true,
        props: {
          message: error.errorId === ApiErrorCode.CC_ALREADY_EXISTS
            ? error.message
            : $t.creditCardSaveFailed,
          type: 'error'
        }
      })
    }
  }

  async function saveGiftCard(payload) {
    try {
      const { card } = await consumer.$savePaymentInsturment(payload)

      return card.paymentInstrumentId
    }
    catch (error) {
      assertApiError(error)
      const toast = useToaster()
      toast.add({
        autoClose: true,
        props: {
          message: error.errorId === ApiErrorCode.CC_ALREADY_EXISTS
            ? $t.giftCardAlreadyExist
            : $t.giftCardSaveFailed,
          type: 'error'
        }
      })
    }
  }

  /**
   * need api for getSavedGiftCards
   */
  const getSavedGiftCards = ref<PaymentInstrument[]>([])
  /**
   * update address by {addressId} and update the store
   * @method PUT
   * @param {UpdateAddressPayload['address']} payload
   * @example useLazyApi('/consumers/v3/consumer/address/{addressId}')
   * This function expects a payload containing either a 'recipientContactPhone', or both 'phone' and 'phoneCode'.
   * When just changing the default address flag, 'recipientContactPhone' is present,
   * while when fully editing the address, 'phone' and 'phoneCode' are present instead.
   */
  async function updateAddress(payload: UpdateAddressPayload['address']) {
    if (!profile.value) throw new Error('Profile not loaded')

    const recipientContactPhone = formatE164phone(
      payload.recipientContactPhone || payload.phone,
      payload.phoneCode
    )
    const addressDetails = { ...payload, recipientContactPhone }
    // remove unnecessary data from the body
    delete addressDetails.phone
    delete addressDetails.phoneCode

    const { addressId } = await consumer.$updateAddress(payload.id, {
      address: addressDetails
    })
    const addressIndex = profile.value.consumerDetails.address.findIndex(
      (address) => address.id === addressId
    )
    if (addressIndex !== -1)
      profile.value.consumerDetails.address[addressIndex] = addressDetails

    if (payload.main)
      setDefaultAddress(addressId)

    return addressId
  }

  /**
   * delete the from api and update the store
   * delete address by {addressId} the Nuxt server endpoint or enpoint from LauchDarkly config.
   * @method DELETE
   * @param {addressId} id
   * @example useLazyApi('/consumers/v3/consumer/address/{addressId}')
   */
  async function deleteAddress(id: string) {
    if (!profile.value) throw (new Error('Profile not loaded'))
    const filteredAddresses = profile.value.consumerDetails.address.filter((address) => address.id !== id)
    await consumer.$deleteAddress(id)
    profile.value.consumerDetails.address = filteredAddresses
  }

  // Clear user data from the store
  const reset = () => {
    data.value = null
  }

  return {
    get: execute,
    pending,
    error,
    interests,
    profile,
    details,
    shippingAddresses,
    billingAddresses,
    defaultShippingAddress,
    defaultBillingAddress,
    preferredShoeSize,
    subscriptions,
    hasNewsletterSubscription,
    updateBasicInfo,
    updateInterests,
    addAddress,
    updateAddress,
    deleteAddress,
    saveCreditCard,
    employeeDiscountDetails,
    athlete,
    isPartiallyEnrolled,
    isLoyaltyMember,
    isWranxProfile,
    isIpaProfile,
    isWranxOrIpaProfile,
    reset,
    getSavedGiftCards,
    saveGiftCard
  }
})
