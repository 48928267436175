<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div v-style:bg="backgroundColor" class="relative grid">
      <div class="relative flex grid-area-stack">
        <div
          v-if="hasCaption"
          v-style:c="captionColor || subtitleColor"
          class="absolute right-0 mr-6"
          :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
          style="max-width: 9.375rem"
        >
          {{ captionText }}
        </div>
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="w-full"
        />
        <base-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          class="w-full"
          fit="cover"
        />
        <div v-style="gradient" class="absolute bottom-0 w-full" />
        <base-link
          v-if="mediaLink"
          :to="mediaLink.url"
          :target="mediaLink.targetAttribute"
          aria-hidden="true"
          tabindex="-1"
          class="absolute-0"
        />
      </div>
      <div
        v-style:bg="contentBackgroundColor"
        class="z-1 f-col items-start gap-6 px-4 pb-4 pt-6 md:absolute md:bottom-0 md:mx-10 md:max-w-120 md:p-8 lg:px-6"
        :class="[contentAlignment, alignmentLarge === 'left' ? 'left-0' : 'right-0']"
      >
        <div class="f-col gap-4">
          <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
            {{ eyebrowText }}
          </div>
          <base-picture
            v-if="eyebrowLogo"
            :src="eyebrowLogo.url"
            :alt="eyebrowLogo.alt"
            :props-img="{ class: 'w-a', style: 'max-height: 9.5rem' }"
            fit="contain"
          />
          <div class="f-col gap-2">
            <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
              {{ overline }}
            </p>
            <h2
              v-if="title"
              v-style:c="titleColor"
              :class="[titleStyle, { 'sr-only': hideTitle }]"
              data-test-id="cms-banner-title"
            >
              <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
                {{ title }}
              </base-link>
              <template v-else>
                {{ title }}
              </template>
            </h2>
            <p
              v-if="subtitle"
              v-style:c="subtitleColor"
              :class="subtitleStyle"
              data-test-id="cms-banner-text"
            >
              {{ subtitle }}
            </p>
            <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
          </div>
        </div>
        <div class="f-col items-inherit gap-4">
          <div
            v-if="linkType !== 'No-CTA' && targets.length"
            v-style="equalTargetStyle"
            class="pointer-within flex-col items-inherit gap-4 wrap md:flex-row"
            :class="ctaClass"
          >
            <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" class="w-fit" />
          </div>
          <cms-rich-text
            v-if="richTextBelowTargets"
            v-style:c="richTextBelowTargetsColor || subtitleColor"
            :content="{ richText: richTextBelowTargets }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BannerOverlayContent } from '#types/components/cms/banner-overlay'
import type { TextAlignment } from '#types/components/cms/text-alignment'
import type { Responsive } from '#types/common'

const { content } = defineProps<{
  content: BannerOverlayContent
}>()

const {
  alignmentLarge,
  backgroundColor,
  contentBackgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  textAlignmentLarge,
  textAlignmentSmall,
  targets = [],
  title,
  titleColor,
  titleStyle,
  hideTitle,
} = content

const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const gradientStyles = { full: 35, focused: 50, off: 0 }

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length},minmax(0,1fr))`
    }
  })
}

const textAlignmentSmallClasses: Record<TextAlignment, string> = {
  left: '<md:items-start <md:text-left',
  center: '<md:items-center <md:text-center',
  right: '<md:items-end <md:text-right'
}

const textAlignmentLargeClasses: Record<TextAlignment, string> = {
  left: 'md:items-start md:text-left',
  center: 'md:items-center md:text-center',
  right: 'md:items-end md:text-right'
}

const contentAlignment = [
  textAlignmentSmallClasses[textAlignmentSmall],
  textAlignmentLargeClasses[textAlignmentLarge],
]

const ctaClass = [
  isEqualWidthButtons ? 'grid' : 'flex',
]

onMounted(() => videoRef.value?.play())
</script>
