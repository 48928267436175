<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div v-style:bg="backgroundColor" class="relative grid">
      <div class="relative flex grid-area-stack">
        <div
          v-if="hasCaption"
          v-style:c="captionColor || subtitleColor"
          class="absolute right-0 mr-6"
          :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
          style="max-width: 9.375rem"
        >
          {{ captionText }}
        </div>
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="w-full"
        />
        <base-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          class="w-full"
          fit="cover"
        />
        <base-link
          v-if="mediaLink"
          :to="mediaLink.url"
          :target="mediaLink.targetAttribute"
          aria-hidden="true"
          tabindex="-1"
          class="absolute-0"
        />
      </div>
      <div
        class="z-1 flex items-center px-4 md:pointer-events-none md:px-12 md:grid-area-stack"
        :style="{ gap: backdrop ? '4.5rem' : '3rem' }"
        :class="contentAlignment"
      >
        <div
          v-style:bg="backdrop"
          class="p-6 -mx-6 space-y-2 md:py-10"
          :class="alignmentSmall.includes('overlay') ? 'p-2 -mx-2' : '<md:bg-transparent'"
        >
          <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
            {{ eyebrowText }}
          </div>
          <base-picture
            v-if="eyebrowLogo"
            :src="eyebrowLogo.url"
            :alt="eyebrowLogo.alt"
            fit="contain"
            :props-img="{ class: 'w-a', style: 'max-height: 9.5rem' }"
          />
          <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
            {{ overline }}
          </p>
          <h2
            v-if="title"
            v-style:c="titleColor"
            :class="[titleStyle, { 'sr-only': hideTitle }]"
            data-test-id="cms-banner-title"
          >
            <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
              {{ title }}
            </base-link>
            <template v-else>
              {{ title }}
            </template>
          </h2>
          <p
            v-if="subtitle"
            v-style:c="subtitleColor"
            :class="subtitleStyle"
            data-test-id="cms-banner-text"
          >
            {{ subtitle }}
          </p>
          <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
        </div>
        <div
          v-if="linkType !== 'No-CTA' && targets.length && !alignmentLarge.includes('center')"
          v-style="equalTargetStyle"
          class="pointer-within gap-4 wrap <md:hidden lg:gap-6"
          :class="ctaClass"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" />
        </div>
      </div>
    </div>
    <div
      v-if="linkType !== 'No-CTA' && targets.length"
      v-style="equalTargetStyle"
      class="f-col gap-4 px-4 pb-6 md:hidden!"
      :class="ctaClass"
    >
      <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  BannerThinAlignmentLarge,
  BannerThinAlignmentSmall,
  BannerThinContent
} from '#types/components/cms/banner-thin'

const { content } = defineProps<{
  content: BannerThinContent
}>()

const {
  alignmentLarge,
  alignmentSmall,
  backdrop,
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowTextColor,
  eyebrowText,
  linkType,
  media,
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
  hideTitle,
} = content

const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const contentAlignmentLarge: Record<BannerThinAlignmentLarge, string> = {
  'left-right': 'md:between',
  'center-center': 'md:justify-center md:text-center'
}

const contentAlignmentSmall: Partial<Record<BannerThinAlignmentSmall, string>> = {
  'left-overlay': '<md:grid-area-stack <md:pointer-events-none <md:grid-area-stack',
  'center-overlay': '<md:pointer-events-none <md:justify-center <md:text-center <md:grid-area-stack',
  'center-below': '<md:justify-center <md:text-center'
}

const ctaAlignmentLarge: Record<BannerThinAlignmentLarge, string> = {
  'left-right': 'md:relative md:col-start-2 md:row-start-1 md:justify-end',
  'center-center': 'md:hidden'
}

const ctaAlignmentSmall: Record<BannerThinAlignmentSmall, string> = {
  'left-overlay': '<md:items-start <md:mt-6',
  'left-below': '<md:items-start',
  'center-overlay': '<md:items-center <md:text-center <md:mt-6',
  'center-below': '<md:items-center <md:text-center'
}

const hasCaption = captionText && captionPosition !== 'off'

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length},minmax(0,1fr))`
    }
  })
}

const contentAlignment = [
  contentAlignmentLarge[alignmentLarge],
  contentAlignmentSmall[alignmentSmall]
]

const ctaClass = [
  isEqualWidthButtons ? 'grid' : 'flex',
  ctaAlignmentSmall[alignmentSmall],
  ctaAlignmentLarge[alignmentLarge]
]

onMounted(() => videoRef.value?.play())
</script>
