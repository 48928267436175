<template>
  <div
    v-style="{
      mb: hasMargins ? content.bottomMargins : null,
    }"
    @click="sendCampaignTracking"
  >
    <client-only v-if="showBaseline">
      <div
        v-for="baseline of baselines"
        :key="baseline.id"
        :class="{
          container: !(baseline.isFullWidth ?? content.isFullWidth),
          hidden: hideBaseline,
        }"
        :data-test-id="`${id}-baseline`"
      >
        <component
          :is="`cms-${baseline.component}`"
          v-style="{
            display: getVisibilityStyles(baseline.hideOnBreakpoint),
            mb: baseline.bottomMargins,
          }"
          :content="baseline"
          :data-test-id="`cms-${baseline.component}-${baseline.id}`"
        />
      </div>

      <template #fallback>
        <div
          v-for="baseline of baselines"
          :key="baseline.id"
          :class="{ container: !(baseline.isFullWidth ?? content.isFullWidth) }"
          :data-test-id="`${id}-fallback`"
        >
          <div class="skeleton">
            <component
              :is="`cms-${baseline.component}`"
              v-style="{
                display: getVisibilityStyles(baseline.hideOnBreakpoint),
                mb: baseline.bottomMargins,
              }"
              :content="baseline"
              class="invisible"
              :data-test-id="`cms-${baseline.component}-${baseline.id}`"
            />
          </div>
        </div>
      </template>
    </client-only>

    <div
      v-for="variant of variants"
      :key="variant.id"
      :class="{
        container: !(variant.isFullWidth ?? content.isFullWidth),
        hidden: variant.name !== selectedVariantName,
      }"
      :data-test-id="`${id}-${variant.name}`"
      :data-test-variant="variantIdOverrides ? variant.id : null"
    >
      <component
        :is="`cms-${variant.component}`"
        v-style="{
          display: getVisibilityStyles(variant.hideOnBreakpoint),
          mb: variant.bottomMargins,
        }"
        :content="{ ...variant, name: content.name, recommendedProducts, monetateDecision }"
        :data-test-id="`cms-${variant.component}-${variant.id}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { Experience } from '#types/components/cms/experience'
import mappings from '#content/mappings'
import type { Content, Module } from '#types/content'
import type { MonetateRecommendedProduct } from '#types/p13n'
import type { Responsive } from '#types/common'

/**
 * Gets the name of the component that belongs to the specified content.
 * @returns the component name.
 * @param content
 */
function getComponent(content: Content | Module) {
  const contentMapping = mappings[content.type]
  return contentMapping?.component || contentMapping?.variants![content.variant!]?.component
}
</script>

<script lang="ts" setup>
const { content } = defineProps<{
  content: Experience
}>()

const query = useRouteQuery('p13n_testcontext', null)
const id = content.experienceId?.split('_').pop() || ''
const monetateDecision = ref(import.meta.server ? null : window.vfa?.get(id))
const { breakpoints } = useAppConfig().ds
const { componentSpacingMap } = useAppConfig().components.cms.section
const { sendMonetateEvents } = useMonetate()
const bps = Object.keys(breakpoints) as (keyof Responsive)[]

const recommendedProducts = ref<MonetateRecommendedProduct[]>(
  monetateDecision.value?.items ? monetateDecision.value.items : []
)

const hasMargins = computed(() => {
  const type = variants.value[0]?.type || ''
  // When a scheduled content has ended
  if (!variants.value.length)
    return false
  // Benefit bar and menu voices should never have margins
  if (type === 'VfCanvasBenefitBar' || type.includes('VfCanvasMegaMenu'))
    return false
  // When no decision is returned and no baseline is present
  if (!monetateDecision.value && !content.baselines?.length)
    return false
  // Even when there is an product recommendations experience but no products are returned
  if (['VfCanvasProductRecommendations', 'VfCanvasProductUpsell'].includes(type) && recommendedProducts.value?.length === 0)
    return false

  return true
})

const reduceMargins = (bottomMargins) =>
  bps.reduce((acc, bp) => ({
    ...acc,
    [bp]: componentSpacingMap[bottomMargins?.[bp]]
  }), {})

const variantIdOverrides = computed(
  () => query.value
    ? JSON.parse(query.value)?.variants?.map((id) => id.split('_').pop())?.filter((id) => id !== 'baseline')
    : null
)

const baselines = computed(() =>
  content.baselines?.map((baseline) => ({
    ...baseline,
    component: getComponent(baseline),
    bottomMargins: reduceMargins(baseline.bottomMargins)
  })).filter((baseline) => baseline !== undefined) || []
)

const mapVariants = (variants) =>
  variants.flatMap(({ name, targets, variantId }) =>
    targets?.map((target) => ({
      ...target,
      component: getComponent(target),
      name,
      bottomMargins: reduceMargins(target.bottomMargins),
      id: variantId
    }))
  ).filter((variant) => variant !== undefined) || []

const variants = ref(mapVariants(content.variants || []))

const selectedVariantName = computed(() => {
  if (import.meta.server) return null
  return content.variants.find(({ name, variantId }) => query.value
    ? variantIdOverrides.value.find((id) => id === variantId)
    : monetateDecision.value?.variant === name)?.name
})

const hideBaseline = computed(() => {
  if (import.meta.server) return false
  return query.value ? selectedVariantName.value : (!!monetateDecision.value && monetateDecision.value?.variant !== 'Baseline')
})

// Used to removed unused baseline nodes after nextTick when not in variant override
const showBaseline = ref(true)

const recalculateMonetateVfa = () => {
  monetateDecision.value = window.vfa?.get(id)
  recommendedProducts.value = monetateDecision.value?.items ?? []
}

const sendCampaignTracking = () => {
  if (content.trackingEvent) {
    sendMonetateEvents([{
      eventType: 'monetate:record:PageEvents',
      pageEvents:
  [
    content.trackingEvent
  ]
    }])
  }
}

whenever(selectedVariantName, (value) => {
  if (!query.value)
    showBaseline.value = !hideBaseline.value
}, { immediate: true })

onMounted(() => {
  window.addEventListener('recalculateMonetateVfa', recalculateMonetateVfa)
})

onBeforeUnmount(() => {
  window.removeEventListener('recalculateMonetateVfa', recalculateMonetateVfa)
})
</script>
