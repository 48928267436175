export const useDialogsStore = defineStore('dialogs', () => {
  const { allowSimplifiedEnrollment } = useFeatureFlags()
  const {
    cartChangeShippingMethod,
    changeStoreConfirmation,
    forgotPassword,
    miniCart,
    pickupInStore,
    quickshop,
    signIn,
    signUp,
    storeChanged
  } = useAppConfig().components.dialog

  return {
    DialogAddGiftOption: createDialog('add-gift-option'),
    DialogAskQuestion: createDialog('ask-question'),
    DialogAskQuestionSuccess: createDialog('ask-question-success'),
    DialogCartChangeShippingMethod: createDialog('cart-change-shipping-method', cartChangeShippingMethod?.dialogOptions),
    DialogChangeStoreConfirmation: createDialog('change-store-confirmation', changeStoreConfirmation?.dialogOptions),
    DialogEmployeeTerms: createDialog('employee-terms'),
    DialogForgotPassword: createDialog('forgot-password', forgotPassword?.dialogOptions),
    DialogInterests: createDialog('interests'),
    DialogKlarna: createDialog('klarna'),
    DialogLocationConfirmation: createDialog('location-confirmation'),
    DialogLoyaltySignUp: createDialog('loyalty-sign-up'),
    DialogMiniCart: createDialog('mini-cart', miniCart?.dialogOptions),
    DialogPickupInStore: createDialog('pickup-in-store', pickupInStore?.dialogOptions),
    DialogQuickshop: createDialog('quickshop', quickshop?.dialogOptions),
    DialogReviewUgc: createDialog('review-ugc'),
    DialogSignIn: createDialog(allowSimplifiedEnrollment ? 'simplified-enrollment' : 'sign-in', signIn?.dialogOptions),
    DialogSignUp: createDialog('sign-up', signUp?.dialogOptions),
    DialogStoreChanged: createDialog('store-changed', storeChanged?.dialogOptions),
    DialogThreeDsChallenge: createDialog('three-ds-challenge'),
    DialogWelcome: createDialog('welcome')
  }
})
